.App {
  display: flex;
  width: 100%;
  color: rgb(255, 255, 255);
  font-family: 'Consolas', sans-serif;
  user-select: none;
  text-align: center;
  flex-direction: column;

  .head {
    margin-top: 48px;
    font-size: 48px;
    padding: 16px;
  }

  .container-top {
    background: dodgerblue;
    min-height: 100vh;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .nameplate {
      
      background-color: rgb(255, 255, 255);
      color: black;
      border: 1px solid white;
      border-radius: 12px;
      padding: 24px;
      display: flex;
      align-items: center;
      margin: 24px;
      border: 1px solid lightgray;
      justify-content: center;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      .nameplate-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 32px;

        .avatar {
          width: 128px;
          margin-bottom: 16px;
          border-radius: 50%;
        }

        .links {
          display: flex;
        }
      }

      .nameplate-text {
        .head {
          margin: 0;
          font-size: 48px;
          padding: 0px;
          font-weight: bold;

          transition: all 0.1s ease-in-out;
        }
        .subhead {
          color: black;
          margin-top: 0;
        }
      }
      @media (max-width: 920px) {
        .nameplate-image {
          .avatar {
            width: 112px;
          }
        }

        .nameplate-text {
          .head {
            font-size: 40px;
          }

          .subhead {
            font-size: 20px;
          }

          h1.subhead {
            font-size: 24px;
          }
        }
      }

      @media (max-width: 720px) {
        .nameplate-image {
          .avatar {
            width: 88px;
          }
        }

        .nameplate-text {
          .head {
            font-size: 32px;
          }

          .subhead {
            font-size: 17px;
          }
        }
      }
    }

    .arrow-down {
      position: absolute;
      bottom: 0;
      text-align: center;
      font-size: 56px;
    }
  }

  .container-bottom {
    background: rgb(31, 77, 122);
    min-height: 100vh;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .loading {
      margin: auto;

      .taking-too-long {
        color: rgb(209, 209, 209);
        opacity: 0;

        transform: translateY(-20px);

        transition: all 0.2s ease-in-out;

        &.shown {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    .error {
      margin: auto;
      color: rgb(238, 79, 79);
    }
  }

  @media (max-width: 1300px) {
    .container-top,
    .container-bottom {
      .head {
        font-size: 32px;
      }

      h1.subhead {
        font-size: 24px;
      }

      hr {
        display: none;
      }
    }

    .container-bottom {
      height: auto;
      padding-bottom: 48px;
    }
  }

  @media (max-width: 350px) {
    .head {
      font-size: 26px !important;
    }

    h1.subhead {
      font-size: 24px;
    }

    h4.subhead {
      font-size: 16px;
    }
  }
}
