.info {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 85%;
  max-width: 1200px;
  text-align: left;
  
  padding-bottom: 80px;

  transition: all 0.1s ease-in-out;

  @media (max-width: 920px) {
    width: 90%;
  }

  h1 {
    font-weight: bold;
    font-size: 40px;
  }

  p {
    font-size: 20px;
    text-indent: 24px;
  }

  .arrow-down {
    text-align: center;
    bottom: 0;
    font-size: 56px;
  }
}
