.project {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1320px;
  align-items: center;
  margin: 16px auto;
  padding: 24px;

  background-color: rgb(36, 111, 187);
  border-radius: 12px;

  transition: background-color 0.5s;
  transition-delay: 0.1s;

  .images {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    object-fit: cover;
    height: max-content;
    max-height: 480px;
    max-width: 100%;

    .thumb {
      box-sizing: content-box;
      width: auto;
      width: 72%;
      max-width: 100%;
      border-radius: 2px;
    }

    .mobile-thumb {
      max-width: 100%;
      width: 27%;
      max-height: 480px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-top: 24px;

    .warn {
      padding: 6px;
      background: rgba(220, 20, 60, 0.56);
      border-radius: 6px;
      width: auto;
      margin-left: 8px;
      font-size: 14px;
    }

    h2 {
      margin: 0;
    }

    h4 {
      margin: 6px 0;
      color: rgb(235, 224, 74);
    }

    p {
      margin-top: 8px;
      font-size: 15px;
    }
  }

  .cta {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      margin: 8px 0;
      padding: 8px;
      margin-right: 16px;
      background: rgba(255, 255, 255, 0);
      border: 1px solid white;
      border-radius: 4px;
      color: white;
      font-weight: bolder;
      cursor: pointer;
      outline: none;

      box-shadow: none;

      transition: background-color 0.25s, box-shadow 0.2s;

      &.disabled {
        pointer-events: none;
        background-color: rgb(27, 67, 110);
        color: rgb(143, 143, 143);
      }

      .glyphicon {
        color: lightgray;
      }
    }

    button:hover {
      background: rgb(98, 169, 255);
      box-shadow: 0 0 7px -2px black;
    }
  }

  @media (min-width: 960px) {
    .cta {
      flex-direction: row;

      button {
        width: 50%;
      }
    }
  }
}
