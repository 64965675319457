a {
  .link {
    border: 1px solid white;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    padding: 4px;
    margin: 4px 8px;
    color: white;

    cursor: pointer;

    transition: all 0.1s ease-in-out;

    &:hover {
      color: black;
      background-color: white;
    }
  }
}
